/* Estilos generales para el contenedor de historial de tutorías */
.historial-tutorias {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-top: 20px;
}

/* Estilos para la sección de filtros */
.filtros {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}

/* Estilos para el contenedor de filtros */
.boostrap {
  margin-bottom: 20px;
  display: flex;
  margin-top: 20px;
  width: 90%; 
  flex-wrap: wrap;
}

/* Estilos para los elementos dentro del contenedor de filtros */
.boostrap > * {
  flex: 1 1 auto; /* Permite que los elementos crezcan y se encojan, pero con un tamaño base automático */
  margin: 5px; /* Añade un pequeño margen entre los elementos */
  min-width: 150px; /* Establece un ancho mínimo para evitar que se compriman demasiado */
}

/* Estilos para las etiquetas de los filtros */
.etiquetas {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  flex-grow: 1; /* Permite que crezca */
}

/* Estilos para los selectores de los filtros */
.margenFiltro {
  margin-top: 6px;
  height: 27px;
  border-radius: 6px;
  width: 100%; /* Cambiado a 100% para que ocupe el espacio disponible */
  max-width: 150px; /* Limitar el ancho máximo */
}

/* Estilos para la tabla responsiva */
.responsive-table {
  max-width: 100%;
  overflow-x: auto;
}

/* Estilos para la tabla de tutorías */
.tabla-tutorias {
  width: 100%;
  border-collapse: separate; /* Usar border-collapse: separate para permitir border-spacing */
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.tabla-tutorias th,
.tabla-tutorias td {
  padding: 8px;
  text-align: left;
  word-wrap: break-word; /* Agregado para que el texto se ajuste */
  height: auto; /* Cambiado a auto para que se ajuste */
}

/* Estilos para los bordes de las celdas */
.tabla-tutorias th,
.tabla-tutorias td {
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ddd;
  padding: 16px   ;
}

.tabla-tutorias th:first-child,
.tabla-tutorias td:first-child {
  border-left: 1px solid #ddd;
}

.tabla-tutorias th:last-child,
.tabla-tutorias td:last-child {
  border-right: 1px solid #ddd;
}

/* Estilos para la fila del encabezado */
.tabla-tutorias thead tr {
  border-top: 10px solid #ddd;
  margin-bottom: 10px solid #ddd;
}
/* Estilos para la última fila del cuerpo de la tabla */
.tabla-tutorias tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}

/* Estilos para el enlace de saltar al contenido */
.skip-to-content {
  position: absolute;
  top: -100px;
  left: -100px;
  font-size: 1px;
  color: transparent;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.skip-to-content:focus {
  position: static;
  top: auto;
  left: auto;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 1px solid #333;
  padding: 10px;
  margin: 10px;
}

/* Estilos para el efecto hover en las filas de la tabla */
.tabla-tutorias tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

/* Estilos para los encabezados de la tabla */
.tabla-tutorias th {
  background-color: #fff; /* Mismo color que las otras filas */
  width: auto; /* Agregado para que se ajuste */
}

/* Media queries para dispositivos móviles */
@media only screen and (max-width: 768px) {
  .tabla-tutorias {
    font-size: 12px;
  }
  .tabla-tutorias th, .tabla-tutorias td {
    padding: 4px;
  }
  .historial-tutorias {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 380px) {
  .tabla-tutorias {
    font-size: 10px;
  }
  .tabla-tutorias th, .tabla-tutorias td {
    padding: 2px;
  }
}

@media only screen and (max-width: 768px) {
  .filtros {
    display: flex;
    flex-direction: column;
  }
}

